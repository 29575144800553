import React from 'react'
import { HashLink } from 'react-router-hash-link'
import PropTypes from 'prop-types'
import Typography from 'components/atoms/Typography'
import { startsWithHttp } from 'helpers/strings'

import './CardInfo.scss'

function CardInfo({ children, text, theme, title, url }) {
  return (
    <HashLink to={url} title={title}>
      <div className={`card-info card card-${theme} ${!children ? 'card-info--half' : ''}`}>
        {title && <p className="card-info__title mb-1">{title}</p>}
        {children && (
          <Typography variant="span" cssClass="card-info__content max-line-7 title-book-sm">
            {children}
          </Typography>
        )}
        {text && (
          <p className="card-info__text">
            <span className="mr-2">{text}</span>
            <i className={`icone-${startsWithHttp(url) ? 'diagonal-arrow-right-up' : 'arrow-right'}`} />
          </p>
        )}
      </div>
    </HashLink>
  )
}

CardInfo.defaultProps = {
  url: '/',
}

CardInfo.propTypes = {
  children: PropTypes.node,
  text: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  url: PropTypes.string.isRequired,
}

export default CardInfo
