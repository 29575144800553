import React from 'react'
import { Container, Row, Col } from 'react-bootstrap'
import ReactHtmlParser from 'react-html-parser'
import CustomButton from 'components/atoms/CustomButton'
import { useHistory } from 'react-router-dom'
import ContentBlock from 'components/atoms/ContentBlock'
import Typography from 'components/atoms/Typography'
import Image from 'components/atoms/Image'
import CardInfo from 'components/molecules/CardInfo'
import { get } from 'lodash'
import { startsWithHttp } from 'helpers/strings'
import { userCheckMobileScreen } from 'helpers/tools'

// Style
import './TextAndLinkWithRightImage.scss'

function TextAndLinkWithRightImage(props) {
  const isMobile = userCheckMobileScreen()
  const history = useHistory()
  const { data } = props
  const url = get(data, 'link.url.path')

  let newsData = get(data, 'news.items[0]')

  return (
    <Container id={get(data, 'id')} fluid="fluid">
      <Container>
        <Row className="py-5">
          <Col xs={12} md={6} lg={5} className="mb-3 mb-lg-0">
            <ContentBlock>
              <Typography variant="h2" color="primary" cssClass="title-bold-sm mb-2">
                {get(data, 'title')}
              </Typography>
              <Typography variant="span" color="gray-100" cssClass="mb-3 mb-lg-4">
                {ReactHtmlParser(get(data, 'description.value'))}
              </Typography>
              <CustomButton
                color="primary"
                id={get(data, 'id') + '_button'}
                text={get(data, 'link.title')}
                iconAlign="right"
                icon={startsWithHttp(url) ? 'diagonal-arrow-right-up' : 'arrow-right'}
                onClick={() => {
                  if (startsWithHttp(url)) {
                    window.open(url)
                  } else {
                    history.push(url)
                  }
                }}
              />
            </ContentBlock>
          </Col>
          <Col className="d-none d-lg-block" lg={4}>
            <div className="text-link-img-container">
              <Image
                src={isMobile ? get(data, 'media.entity.images.medium.url') : get(data, 'media.entity.images.wide.url')}
                alt={get(data, 'media.entity.images.alt')}
                shadow={false}
                roundedCorners={true}
              />
            </div>
          </Col>
          <Col xs={12} md={6} lg={3} className="mt-3 mt-md-0">
            <CardInfo
              color="primary"
              title={get(newsData, 'title')}
              text="Leia mais"
              url={get(newsData, 'url.path')}
              theme="primary outline"
            >
              {get(newsData, 'body.value') && get(newsData, 'body.summary')}
            </CardInfo>
          </Col>
        </Row>
      </Container>
    </Container>
  )
}

export default TextAndLinkWithRightImage
